<!-- Used for admin to add club or dept -->
<!-- TODO: Beautify the layout -->
<template>
	<div id="addasso-wrapper">
		<h2>添加协会</h2>
		<ul class="addasso-additem">
			<li>
				<p class="textleft">类型</p>
				<el-input
					class="addasso-additem-input"
					placeholder="协会"
					:disabled="true"
				/>
			</li>
			<li>
				<p class="textleft">名称</p>
				<el-input
					class="addasso-additem-input"
					v-model="inputName"
					placeholder="协会名称"
				></el-input>
			</li>
			<li>
				<p class="textleft">密码</p>
				<form>
					<el-input
						class="addasso-additem-input"
						v-model="inputPasswd"
						placeholder="初始密码"
						show-password
					></el-input>
				</form>
			</li>
			<li>
				<p class="textleft">协会联系人</p>
				<el-input
					class="addasso-additem-input"
					v-model="inputContactName"
					placeholder="联系人"
				></el-input>
			</li>
			<li>
				<p class="textleft">联系电话</p>
				<el-input
					class="addasso-additem-input"
					v-model="inputContactPhone"
					placeholder="联系电话"
				></el-input>
			</li>
			<li>
				<p class="textleft">联系邮箱</p>
				<el-input
					class="addasso-additem-input"
					v-model="inputContactEmail"
					placeholder="联系邮箱"
				></el-input>
			</li>
			<li>
				<p class="textleft">协会介绍</p>
				<el-input
					class="addasso-additem-input"
					type="textarea"
					placeholder="请输入内容（可留空）"
					v-model="inputDetail"
					maxlength="100"
					show-word-limit
				/>
			</li>
			<li>
				<el-button
					class="addbtn"
					type="danger"
					style="float: left"
					@click="clearall()"
					>重填</el-button
				>
				<el-button
					class="addbtn"
					type="primary"
					style="float: right"
					@click="commitCheck"
					>提交</el-button
				>
			</li>
		</ul>
		<p></p>
		<el-dialog title="确认提交" :visible.sync="commitVisible" width="30%">
			<span style="color: red">您将提交以下协会信息</span>
			<p />
			<p>协会名称： {{ this.inputName }}</p>
			<p>初始密码： {{ this.inputPasswd }}</p>
			<p>联系人： {{ this.inputContactName }}</p>
			<p>联系电话： {{ this.inputContactPhone }}</p>
			<p>联系邮箱： {{ this.inputContactEmail }}</p>
			<p v-if="this.inputDetail">协会介绍： {{ this.inputDetail }}</p>
			<span slot="footer" class="dialog-footer">
				<el-button @click="commitVisible = false" style="margin-right: 4px"
					>取消</el-button
				>
				<el-button type="primary" @click="commitNow()">确定</el-button>
			</span>
		</el-dialog>
	</div>
</template>
<style scoped>
.addasso-additem {
	list-style-type: none;
	display: block;
	width: 40rem;
	margin: auto;
}
.addasso-additem >>> li {
	margin-bottom: 5px;
}
.addasso-additem .textleft {
	text-align: left;
	position: relative;
	padding-left: 8px;
	border-left: 4px solid #1fcab3;
}
.addasso-additem-input {
	width: 100%;
}
li .addbtn {
	margin: 0.7rem 5%;
	width: 35%;
}
</style>
<script>
import axios from "axios";
import qs from "qs";

export default {
	name: "AddClub",
	data() {
		return {
			inputName: "",
			inputPasswd: "",
			inputContactName: "",
			inputContactPhone: "",
			inputContactEmail: "",
			inputDetail: "",
			commitVisible: false,
			clearVisible: false,
		};
	},
	methods: {
		messageBox(msg, type) {
			this.$message[type](msg);
		},
		clearall() {
			this.$confirm("确认清空？").then((_) => {
				console.log(_);
				this.inputName = "";
				this.inputPasswd = "";
				this.inputContactName = "";
				this.inputContactPhone = "";
				this.inputContactEmail = "";
				this.inputDetail = "";
			});
		},
		commitCheck() {
			if (!this.inputName) {
				this.messageBox("请填写协会名称", "error");
				return false;
			}
			if (this.inputName.length < 3 || this.inputName.length > 30) {
				this.messageBox("协会名称长度应在3-30位之间", "error");
				return false;
			}
			if (!this.inputPasswd) {
				this.messageBox("请填写默认密码", "error");
				return false;
			}
			if (this.inputPasswd.length < 6 || this.inputPasswd.length > 30) {
				this.messageBox("默认密码长度应在6-30位之间", "error");
				return false;
			}
			if (
				!this.inputContactName &&
				!this.inputContactPhone &&
				!this.inputContactEmail
			) {
				this.messageBox("请补全联系人信息", "error");

				return false;
			}
			this.commitVisible = true; // TODO: notice after check
			if (!this.inputName) return false;
			if (!this.inputPasswd) return false;
			if (
				!this.inputContactName &&
				!this.inputContactPhone &&
				!this.inputContactEmail
			)
				return false;
			this.commitVisible = true;
		},
		commitNow() {
			if (localStorage.getItem("token")) {
				axios.defaults.headers.common["Authorization"] = localStorage.getItem(
					"token"
				);
			}
			this.commitVisible = false;
			axios({
				url: "/api/admin/asso/add",
				method: "POST",
				headers: {
					"content-type": "application/x-www-form-urlencoded",
				},
				data: qs.stringify({
					type: "club",
					clubname: this.inputName,
					clubinfo: this.inputDetail,
					clubgames: "[]",
					clubcontact: JSON.stringify({
						name: this.inputContactName,
						phone: this.inputContactPhone,
						email: this.inputContactEmail,
					}),
					password: this.inputPasswd,
				}),
			})
				.then((res) => {
					console.log(res);
					this.messageBox("添加" + this.inputName + "成功", "success");
					this.inputName = "";
					this.inputPasswd = "";
					this.inputContactName = "";
					this.inputContactPhone = "";
					this.inputContactEmail = "";
					this.inputDetail = "";
					this.inputGroup = "甲";
				})
				.catch((err) => {
					this.messageBox(
						"添加失败， " + err?.response?.data?.Message ?? "网络断开或神秘错误",
						"error"
					);
					console.log("Add club Failed");
					console.log(err.response);
				});
		},
	},
};
</script>